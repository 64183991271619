export const carList = [
    {
        value: 2,
        label: '电单车'
    },
    {
        value: 3,
        label: '直流桩'
    },
    {
        value: 4,
        label: '交流桩'
    }
]